<template>
    <div>
        <form-wizard v-if="!isLoading"
                     color="#ffbb00"
                     :title="null"
                     :subtitle="null"
                     shape="square"
                     finish-button-text="Submit"
                     back-button-text="Previous"
                     class="mb-3"
                     @on-complete="formSubmitted"
        >
            <!-- accoint details tab -->
            <tab-content title="Step 1" subtitle="Name, Theme, Genre And Brief." :before-change="validationForm">
                <validation-observer ref="conceptStarter" tag="form">
                    <b-row>
                        <b-col md="4">
                            <b-form-group label="Name" label-for="name">
                                <validation-provider #default="{ errors }" name="Concept Name" rules="required">
                                    <b-form-input v-model="conceptCreate.name" :state="errors.length > 0 ? false:null"
                                                  placeholder="Concept Name"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Theme" label-for="theme">
                                <validation-provider #default="{ errors }" name="Concept Theme" rules="required">
                                    <b-form-input id="theme" v-model="conceptCreate.theme"
                                                  :state="errors.length > 0 ? false:null"
                                                  placeholder="Concept Theme"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col :md="4" v-if="genreFetchOptions">
                            <validation-provider #default="{ errors }" name="Genre" rules="required">
                                <b-form-group label="Genre" label-for="name" :state="errors.length > 0 ? false:null">

                                    <v-select id="concept_genre_id" v-model="conceptCreate.concept_genre_id"
                                              :options="genreFetchOptions" :reduce="(option) => option.value"
                                              label="label" placeholder="Select Genre" class="w-100">
                                    </v-select>

                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="12" class="mt-1">
                            <validation-provider #default="{ errors }" name="Brief Description" rules="required">
                                <b-form-group label="Brief Description" label-for="briefdesc"
                                              :state="errors.length > 0 ? false:null">
                                    <quill-editor :options="editorOption" id="quilBrief" v-model="conceptCreate.brief"/>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="12">
                            <hr/>
                        </b-col>
                    </b-row>
                </validation-observer>
            </tab-content>

            <tab-content title="Step 2" subtitle="Camera Style, Reference Links And Game Features"
                         :before-change="validationFormInfo">
                <validation-observer ref="infoRules" tag="form">
                    <b-row>

                        <b-col md="12">
                            <validation-provider #default="{ errors }" name="Camera Style" rules="required">
                                <b-form-group label="Camera Style" label-for="camera-style"></b-form-group>
                                <b-row>
                                    <b-col :md="editColCameraColumn(cameraFetchOptions)"
                                           v-for="camera in cameraFetchOptions" :key="camera.value">
                                        <div class="option_button">
                                            <input type="radio" :id="editColCameraColumnID(camera)"
                                                   v-model="conceptCreate.concept_camera_id" :value="camera.value">
                                            <label :for="editColCameraColumnID(camera)">
                                                <span v-html="camera.icon"></span>
                                                <div class="custom-option-item-title h4 d-block">{{ camera.label }}
                                                </div>
                                            </label>
                                        </div>
                                    </b-col>

                                </b-row>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-col>


                        <b-col md="12">
                            <hr/>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Reference Links" label-for="reference-links"></b-form-group>
                            <div>
                                <b-form ref="form" :style="{height: trHeight}" class="repeater-form"
                                        @submit.prevent="repeateAgain">
                                    <b-row v-for="(item, index) in conceptCreate.references" :id="item.id"
                                           :key="item.id" ref="row">
                                        <b-col md="4" class="mb-1">
                                            <b-form-input id="reference-links" v-model="item.link" type="text"
                                                          placeholder="Appstore Links, Gameplay videos, Trailers etc"/>
                                        </b-col>
                                        <b-col md="8" class="mb-1">
                                            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                                                      class="mt-0"
                                                      @click="removeItem(index)">
                                                <feather-icon icon="XIcon" class="mr-25"/>
                                                <span>Delete</span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </div>

                            <div>
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                          @click="repeateAgain">
                                    <feather-icon icon="PlusIcon" class="mr-25"/>
                                    <span>Add Reference Link</span>
                                </b-button>
                            </div>
                        </b-col>
                        <b-col md="12">
                            <hr/>
                        </b-col>

                        <b-col md="12">
                            <b-form-group label="Game Features" label-for="game-features" class="mb-0"></b-form-group>
                            <app-collapse>
                                <app-collapse-item title="Level System">
                                    <quill-editor :options="editorOption"
                                                  v-model="conceptCreate.level_system_description"/>
                                </app-collapse-item>

                                <app-collapse-item title="Unlockable Content">
                                    <quill-editor :options="editorOption"
                                                  v-model="conceptCreate.unlockable_content_description"/>
                                </app-collapse-item>

                                <app-collapse-item title="Score System">
                                    <quill-editor :options="editorOption"
                                                  v-model="conceptCreate.score_system_description"/>
                                </app-collapse-item>

                                <app-collapse-item title="Upgrade System">
                                    <quill-editor :options="editorOption"
                                                  v-model="conceptCreate.upgrade_system_description"/>
                                </app-collapse-item>
                            </app-collapse>
                        </b-col>

                        <b-col md="12">
                            <hr/>
                        </b-col>
                    </b-row>


                </validation-observer>
            </tab-content>

            <tab-content title="Step 3" subtitle="Control, Loop, Art Style, Meta, Retention, Additional and Status"
                         :before-change="validationFormControl">
                <validation-observer ref="lastRulesControll" tag="form">
                    <b-row>

                        <b-col md="12">
                            <validation-provider #default="{ errors }" name="Controls" rules="required">
                                <b-form-group label="Controls" label-for="controls"
                                              :state="errors.length > 0 ? false:null">
                                    <quill-editor :options="editorOption" id="controls"
                                                  v-model="conceptCreate.controls"/>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="12" class="mt-1">
                            <validation-provider #default="{ errors }" name="Game Loop" rules="required">
                                <b-form-group label="Game Loop" label-for="loop"
                                              :state="errors.length > 0 ? false:null">
                                    <quill-editor :options="editorOption" id="loop" v-model="conceptCreate.loop"/>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="12" class="mt-1">
                            <validation-provider #default="{ errors }" name="Art Style" rules="required">
                                <b-form-group label="Art Style" label-for="artstyle"
                                              :state="errors.length > 0 ? false:null">
                                    <quill-editor :options="editorOption" id="artstyle"
                                                  v-model="conceptCreate.artstyle"/>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="12">
                            <app-collapse>
                                <app-collapse-item title="Meta Game (Optional)">
                                    <quill-editor :options="editorOption" v-model="conceptCreate.meta"/>
                                </app-collapse-item>

                                <app-collapse-item title="Retention Features (Optional)">
                                    <quill-editor :options="editorOption" v-model="conceptCreate.retention"/>
                                </app-collapse-item>

                                <app-collapse-item title="Addional Details (Optional)">
                                    <quill-editor :options="editorOption" v-model="conceptCreate.additional"/>
                                </app-collapse-item>

                            </app-collapse>

                        </b-col>

                        <b-col md="12">
                            <hr/>
                        </b-col>

                        <b-col cols="12" md="3" class="mb-md-0 mb-2" v-if="$can('read', 'conceptApprove')">
                            <validation-provider #default="{ errors }" name="statusSelect" rules="required">
                                <b-form-group label="Status" label-for="statusSelect"
                                              :state="errors.length > 0 ? false:null">
                                    <v-select id="statusselect"
                                              :options="statusOptions" :reduce="(option) => option.value"
                                              v-model="conceptCreate.status"
                                              placeholder="Select Status" class="w-100"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <hr/>
                        </b-col>

                    </b-row>
                </validation-observer>
            </tab-content>

        </form-wizard>

        <b-card v-if="isLoading" class="mt-2">
            <div class="text-center my-2">
                <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                <strong>Content is being created, please wait...</strong>
            </div>
        </b-card>


    </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import vSelect from 'vue-select'
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import 'quill/dist/quill.snow.css'

import {quillEditor} from 'vue-quill-editor'
import useConceptApi from '@/composables/useConceptApi'
import {heightTransition} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@/components/AppCollapse.vue'
import AppCollapseItem from '@/components/AppCollapseItem.vue'

import {
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BSpinner,
    BCard,
} from 'bootstrap-vue'
import {required} from '@validations'

export default {
    components: {
        quillEditor,
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BForm,
        BButton,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
        BFormCheckbox,
        AppCollapse,
        AppCollapseItem,
        BSpinner,
        BCard,
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    created() {
        window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
    data() {
        return {
            conceptCreate: {
                brief: '',
                name: '',
                theme: '',
                concept_genre_id: '',
                concept_camera_id: '',
                level_system_description: '',
                unlockable_content_description: '',
                score_system_description: '',
                upgrade_system_description: '',
                controls: '',
                loop: '',
                artstyle: '',
                meta: '',
                retention: '',
                additional: '',
                references: [],
                status: 'Waiting Approval',
            },
            required,
            nextRefId: 0,
            isLoading: false,
            editorOption: {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'],
                        ['blockquote'],
                        ['image', 'video'],
                        [{'header': 1}, {'header': 2}],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        [{'indent': '-1'}, {'indent': '+1'}],
                        [{'color': []}, {'background': []}],
                        [{'align': []}],
                        ['clean']
                    ],
                    imageResize: {
                        displaySize: true
                    }
                },
            },
        }
    },
    methods: {
        formSubmitted() {
            this.isLoading = true
            this.$store.dispatch('conceptStore/addConcept', this.conceptCreate)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Successfully added!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    router.push({name: 'concept-detail', params: {concept: response.data.data.id}})
                }).catch((errors) => {
                this.isLoading = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Please check form',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: errors.response ? errors.response.data.message : ''
                    },
                })
            })
        },
        validationFormControl() {
            return new Promise((resolve, reject) => {
                this.$refs.lastRulesControll.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        validationForm() {
            return new Promise((resolve, reject) => {
                this.$refs.conceptStarter.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        validationFormInfo() {
            return new Promise((resolve, reject) => {
                this.$refs.infoRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        repeateAgain() {
            this.conceptCreate.references.push({
                id: this.nextRefId += 1,
            })
            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
        },
        removeItem(index) {
            this.conceptCreate.references.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight)
            })
        },
        editColCameraColumn(data) {
            const intcol = 12 / data.length
            return parseInt(intcol, 0);
        },
        editColCameraColumnID(camera) {
            return 'camera_' + camera.value;
        }
    },
    setup() {
        const {
            fetchGenre,
            genreFetchOptions,
            fetchCamera,
            cameraFetchOptions,
            statusOptions
        } = useConceptApi()

        return {
            fetchGenre,
            genreFetchOptions,
            fetchCamera,
            cameraFetchOptions,
            statusOptions
        }
    },
    mounted() {
        this.fetchGenre()
        this.fetchCamera()
        this.initTrHeight()
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.ql-editor {
    min-height: 150px;
}

.ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #ebe9f1 !important;
}

.ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #ebe9f1 !important;
}

.is-invalid .quill-editor {
    border: 1px solid red;
    border-radius: 5px;
}

.repeater-form {
    overflow: hidden;
    transition: .35s height;
}
</style>
